<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'main-router',
  props: {
  },
  data() {
    return {
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    // this.$destroy()
  },
  methods: {
    init() {
      this.$router.push({ path: this.$store.state.systemInfo.mainUrl });
    },
  }
};
</script>